import React from 'react';
import styled from 'styled-components';
import { Section } from '../../styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import WhyImg from '../../images/brand-icon-half.png';
/*
import tradeIcon1 from '../../images/trade-1.svg';
import tradeIcon2 from '../../images/trade-2.svg';
import tradeIcon3 from '../../images/trade-3.svg';
import tradeIcon4 from '../../images/trade-4.svg';
import tradeIcon5 from '../../images/trade-5.svg';
import tradeIcon6 from '../../images/trade-6.svg';
import tradeIcon7 from '../../images/trade-7.svg';
*/

const Title = styled.h2`
  //font-size: 1.5rem;
  //color: ${props => props.theme.primaryColor};
  text-align: center;
  margin-bottom: 2rem;
  @media(min-width: 768px){
    max-width: 50%;
  }
`
const Paragraph = styled.p`
  width: 70%;
  text-align: center;
`
const Bold = styled.span`
  font-weight: bold;
`

const WhyCont = styled.div`
  
`
const WhyInfoCont = styled.div`
  
`
const WhyTitle = styled(Title)`
  text-align: left;
  margin-bottom: 2rem;
`
const WhySubTitle = styled(Bold)`
  //border-bottom: 2px solid hsl(3, 55%, 85%);
  line-height: 1.5rem;
  text-transform: uppercase;
  //font-size: 1.2rem;
`
const WhyIcon = styled.img`
  width: 50px;
  margin-right: .5rem;
`
const WhyParagraph = styled(Paragraph)`
  text-align: left;
  margin-bottom: 2rem;
`
const WhyList = styled.ol`
  font-size: .8rem;
  list-style: none;
  padding: 0;
`
const WhyItem = styled.li`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`
const Red = styled.span`
  color: gray;  
`
const WhyImage = styled.div`
  background-image: url("${WhyImg}");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: .3;
  height: 100%;
`
export default ()=> (
  <Section>
    <Container>
      <WhyCont>
        <Row>
          <Col xs={12} md={2}>
            
          </Col>
          {/**
          <Col xs={12} md={10}>
            <WhyInfoCont>
              <WhyTitle>
                ¿Por qué Invierte Bien Raíz?
              </WhyTitle>
              {/* <WhyParagraph>
                Hemos digitalizado las agencias de corretaje convencional por una amplia red de +500 corredores independientes, profesionales y mas centrado en las personas y sus necesidades.
              </WhyParagraph> }
            </WhyInfoCont>     
            <Row>
              <Col xs={12} md={6}>
                <WhyList>
                  <WhyItem>
                    <WhyIcon src={tradeIcon2} alt="" />
                    <WhySubTitle>
                      Valorización comercial sin costo
                    </WhySubTitle>
                    {/* <p>
                      Te decimos el precio justo, de forma profesional, rápida y precisa.
                    </p> }
                  </WhyItem>
                  <WhyItem>
                    <WhyIcon src={tradeIcon3} alt="" />
                    <WhySubTitle>
                      Publicación y promoción en todos los portales inmobiliarios
                    </WhySubTitle>
                    {/* <p>
                      Copywriting, fotografías profesionales y tour virtuales.
                    </p> }
                  </WhyItem>
                  <WhyItem>
                    <WhyIcon src={tradeIcon1} alt="" />
                    <WhySubTitle>
                      Marketing en 360, fotos profesionales, tour virtuales y más
                    </WhySubTitle>
                    {/* <p>
                      Marketing digital, funnel de ventas y exposición en todos los portales inmobiliarios.
                    </p> }
                  </WhyItem>
                  <WhyItem>
                    <WhyIcon src={tradeIcon4} alt="" />
                    <WhySubTitle>
                      La mejor red de corredores asociados vendiendo tu propiedad
                    </WhySubTitle>
                    {/* <p>
                      Gracias a nuestro marketing en red llegaras a mas personas interesadas en comprar tu propiedad
                    </p> }
                  </WhyItem>                                                                                              
                </WhyList>
              </Col>
              <Col xs={12} md={6}>
                <WhyList>
                  <WhyItem>
                    <WhyIcon src={tradeIcon5} alt="" />
                    <WhySubTitle>
                      Te acompañamos en todo el proceso legal
                    </WhySubTitle>
                    {/* <p>
                    Reportes, perfilamiento de clientes, gestión legal y documentación.
                    </p> }
                  </WhyItem>
                  <WhyItem>
                    <WhyIcon src={tradeIcon6} alt="" />
                    <WhySubTitle>
                      Te ayudamos a conseguir el mejor financiamiento
                    </WhySubTitle>
                    {/* <p>
                    Un equipo a tu disposición para gestionar los mejores créditos hipotecarios.
                    </p> }
                  </WhyItem>
                  <WhyItem>
                    <WhyIcon src={tradeIcon7} alt="" />
                    <WhySubTitle>
                      ¿Quieres vender al instante? Recibe una oferta en pocas horas
                    </WhySubTitle>
                    {/* <p>
                    Accede a nuestro producto Ibuyer y recibes una oferta al contado en solo días.
                    </p> }
                  </WhyItem>  
                </WhyList>
              </Col>                
            </Row>       
          </Col>
          */}            
        </Row>
      </WhyCont>     
    </Container>
  </Section>
)
